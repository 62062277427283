<script lang="ts" setup>
import { computed, ref } from 'vue'
import MealSummary from './MealSummary.vue'
import { type MealStory, getMealPouchImage } from '@/models/storyblok/MealStory'
import { getMealBowlImage } from '@/models/storyblok/MealStory'

const props = defineProps<{
  meals: MealStory[]
}>()

const VISIBLE_POUCH_COUNT = 5

const direction = ref<'forward' | 'backward'>('forward')

const offset = ref(0)

const activeIndex = (VISIBLE_POUCH_COUNT - 1) / 2

const visibleMeals = computed(() => {
  const index = offset.value
  const before = props.meals.slice(0, index)

  return [...props.meals.slice(index), ...before].slice(0, VISIBLE_POUCH_COUNT)
})

const activeMeal = computed<MealStory | undefined>(() => {
  const meal = visibleMeals.value[activeIndex]

  return meal
})

const next = (): void => {
  offset.value = (offset.value + 1) % props.meals.length
  direction.value = 'forward'
}

const prev = (): void => {
  offset.value = (offset.value - 1 + props.meals.length) % props.meals.length
  direction.value = 'backward'
}

const isAdjacentPouch = (index: number): boolean => {
  const prev = activeIndex - 1 === index
  const next = activeIndex + 1 === index

  return prev || next
}
</script>

<template>
  <div class="meal-carousel">
    <div class="meal-carousel-reel">
      <TransitionGroup tag="div" name="carousel" class="meal-carousel-pouches">
        <div
          v-for="(meal, index) in visibleMeals"
          :key="meal.id"
          class="meal-carousel-pouch"
          :class="{
            'meal-carousel-pouch-active': activeIndex === index,
            'meal-carousel-pouch-adjacent': isAdjacentPouch(index),
          }"
        >
          <div class="meal-carousel-pouch-image">
            <img
              v-if="meal.content.bowl"
              :src="getMealPouchImage(meal)"
              :alt="`A pouch of Lyka ${meal.name}`"
              height="320"
            />
          </div>
        </div>
      </TransitionGroup>

      <div class="meal-carousel-bowl">
        <button type="button" class="meal-carousel-nav" @click="prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23"
            fill="none"
            viewBox="0 0 24 23"
            class="tw-w-[1em] tw-h-[1em]"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.8"
              d="M21.8 11.5H2.2m0 0L12 1.7m-9.8 9.8 9.8 9.8"
            />
          </svg>
        </button>

        <div v-if="activeMeal" class="tw-max-h-80">
          <Transition :key="activeMeal.id" appear :name="direction === 'forward' ? 'bowl-forward' : 'bowl-backward'">
            <img
              :src="getMealBowlImage(activeMeal)"
              :alt="`A bowl of Lyka ${activeMeal.name}`"
              width="320"
              height="320"
              class="tw-object-cover"
            />
          </Transition>
        </div>

        <button type="button" class="meal-carousel-nav" @click="next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="none"
            viewBox="0 0 23 23"
            class="tw-w-[1em] tw-h-[1em]"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.8"
              d="M1.7 11.5h19.6m0 0-9.8-9.8m9.8 9.8-9.8 9.8"
            />
          </svg>
        </button>
      </div>

      <slot />
    </div>

    <MealSummary v-if="activeMeal" :meal="activeMeal" />
  </div>
</template>

<style scoped>
.meal-carousel {
  @apply tw-flex tw-flex-col tw-items-center lg:tw-flex-nowrap tw-justify-center lg:tw-gap-10 xl:tw-gap-20 lg:tw-flex-row lg:tw-items-start tw-overflow-x-hidden tw-w-auto -tw-mx-6 tw-px-6 tw-pt-6;
}

.meal-carousel-reel {
  @apply tw-inline-grid;
  @apply tw-justify-center;
}

.meal-carousel-pouches {
  @apply tw-inline-flex tw-justify-center tw-relative;
}

.meal-carousel-pouch {
  @apply tw-relative tw-max-w-[7rem] tw-transition-all tw-duration-300 tw-flex tw-items-end tw-justify-center;
}

.meal-carousel-pouch-image {
  @apply tw-scale-[2] tw-origin-top tw-transition-transform tw-relative tw-isolate;
}

.meal-carousel-pouch-adjacent {
  @apply tw-z-[2];
}

.meal-carousel-pouch-adjacent .meal-carousel-pouch-image {
  @apply tw-scale-[2.5] -tw-translate-y-[40%];
}

.meal-carousel-pouch-active {
  @apply tw-z-[3];
}

.meal-carousel-pouch-active .meal-carousel-pouch-image {
  @apply tw-scale-[3] -tw-translate-y-[60%];
}

.meal-carousel-pouch-active .meal-carousel-pouch-image::after {
  @apply tw-opacity-0;
}

.meal-carousel-nav {
  @apply tw-w-10 tw-h-10 md:tw-w-12 md:tw-h-12 tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-2xl md:tw-text-3xl tw-transition-colors tw-relative tw-z-[5];
  @apply tw-bg-dark tw-text-mint-green hover:tw-bg-alt-hover hover:tw-text-white;
}

.meal-carousel-bowl {
  @apply tw-flex tw-items-center tw-gap-2 tw-justify-center tw-relative tw-z-[4] -tw-mt-[10%];
}

.meal-carousel-move,
.meal-carousel-enter-active,
.meal-carousel-leave-active {
  @apply tw-translate-x-0 tw-opacity-100;
}

.meal-carousel-enter-from {
  @apply tw-opacity-0 tw-translate-x-1/2;
}

.meal-carousel-leave-active {
  @apply -tw-translate-x-1/2 tw-left-0 tw-opacity-0 tw-z-0 tw-absolute tw-w-0;
}

.bowl-forward-enter-active,
.bowl-backward-enter-active {
  @apply tw-duration-500;
  @apply tw-ease-out;
}

.bowl-forward-enter-from,
.bowl-backward-enter-from {
  @apply tw-transform tw-opacity-0;
}

.bowl-forward-enter-to,
.bowl-backward-enter-to {
  @apply tw-opacity-100;
}

.bowl-forward-leave-active,
.bowl-backward-leave-active {
  @apply tw-ease-in;
}

.bowl-forward-leave-from,
.bowl-backward-leave-from {
  @apply tw-opacity-100;
}

.bowl-forward-leave-to,
.bowl-backward-leave-to {
  @apply tw-transform tw-opacity-0;
}

.bowl-forward-leave-to {
  @apply -tw-translate-x-1/4;
}

.bowl-backward-leave-to {
  @apply tw-translate-x-1/4;
}
</style>
